import { UNSUPPORTED_SECONDARY_INDUSTRIES } from "../models/industry";
import { OnboardingMerchant } from "../models/onboardingMerchant";
import { SUPPORTED_PLATFORMS } from "../models/platform";

export const hasUnsupportedPlatform = (merchant: OnboardingMerchant): boolean =>
  merchant.businessInfoCompleted &&
  !SUPPORTED_PLATFORMS.some((p) => p.systemName === merchant.platform);

export const hasUnsupportedIndustry = (
  merchant: OnboardingMerchant,
): boolean => {
  return (
    merchant.businessInfoCompleted &&
    UNSUPPORTED_SECONDARY_INDUSTRIES.some(
      (i) => i === merchant.businessInfo?.secondaryIndustry,
    )
  );
};

export const hasNonUsBankAccount = (merchant: OnboardingMerchant): boolean =>
  merchant.businessInfo?.hasUsBankAccount === false;

export const hasFailedDueDiligence = (
  merchant: OnboardingMerchant,
): boolean => {
  return (
    merchant.dueDiligenceSucceeded !== undefined &&
    !merchant.dueDiligenceSucceeded
  );
};

export const isMerchantDeclined = (merchant: OnboardingMerchant): boolean =>
  hasUnsupportedPlatform(merchant) ||
  hasUnsupportedIndustry(merchant) ||
  hasFailedDueDiligence(merchant) ||
  hasNonUsBankAccount(merchant);
