/**
 * Note: these types need to stay in sync with what is in Contentful for anything to remotely stay in sync.
 * 
 * Contentful data model repo here: https://github.com/the-urge-tech/contentful-terraforms
 */
import { AssetHyperlink, Document } from '@contentful/rich-text-types';

// contentful uses magento2 instead of magento_2
// so map any platform names to contentful names here
export const getPlatform = (platform?: string): string => {
  if (!platform) {
    return PLATFORM_FALLBACK_VALUE;
  }

  if (platform === "magento_2") {
    return "magento2";
  }

  return platform;
}

export enum EntityTypes {
  AddWidget = "onboarding_hub_widget_instruction",
  AddCheckoutPlugin = "onboarding_hub_checkout_plugin_instruction",
  Placeholder = "onboarding_hub_placeholder",
  MarketingCopy = "onboarding_hub_marketing",
}

export enum PlaceholderType {
  MerchantId = "merchant-id",
  ClientId = "client-id",
  ClientSecret = "client-secret",
  ApiKey = "api-key",
}

export enum IntegrationPlatform {
  WooCommerce = "woocommerce",
  Shopify = "shopify",
  Magento = "magento"
}

export const PLATFORM_FALLBACK_VALUE = "custom"

export enum Region {
  US = "US"
}

export type AddWidgetFields = {
  platform: IntegrationPlatform,
  region: Region,
  content: Document,
}

export type AddCheckoutPluginFields = {
  platform?: IntegrationPlatform,
  region: Region,
  content: Document,
}

export type MarketingCopyFields = {
  region: Region;
  path: string;

  title?: string;
  content: Document;
  icon?: AssetHyperlink;
}